<template>
	<w-btn color="primary" :disabled="disabled" :flat="flat" icon="visibility" :loading="loading" :mini="mini" :small="small" v-on="$listeners">
		<template v-if="!noText">
			{{ text || $t('actions.view') }}
		</template>
	</w-btn>
</template>

<script>
import ButtonParentClass from '@/components/Commons/Buttons/ButtonParentClass'

/**
 * @displayName w-btn-delete
 *
 * @since 0.3.2
 */
export default {
	extends: ButtonParentClass
}
</script>
